import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import ValidationForm from "../containers/auth/validation";


const Validation = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>Togathr | Validation</title>
        <meta
          name="description"
          content="About page of React VOIP, Telecom and Cloud Services Template."
        />
      </MetaTags>
      <LayoutOne>
        <ValidationForm/>
      </LayoutOne>
    </Fragment>
  );
};

export default Validation;
