import PropTypes from "prop-types";
import React, {useState, useEffect} from "react";

import Button from "../UI/button";
import axios from "axios";
import Swal from 'sweetalert2'
import ApiUrl from "../../utils";

const SubscriptionContent = ({ styles }) => {
  
	const [user, setUser] = useState({});

	useEffect(() => {
		const config = {
			headers: { Authorization: 'Bearer ' + localStorage.getItem("token") }
		};
		axios.get(ApiUrl(`user/me`), config).then(resp => {
			setUser(resp.data);
			localStorage.setItem('user', JSON.stringify(resp.data));
			localStorage.setItem("isAdmin", resp.data.role === "admin");
		}).catch(err => {
			window.location.href = "/login";
		});
  	}, []);

  const openStripePage = (e) => {
	e.preventDefault();
	const config = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem("token") }
	};
	axios.get(ApiUrl(`user/me`), config).then(resp => {
		localStorage.setItem('user', JSON.stringify(resp.data));
		axios.post(ApiUrl(`stripe/portal`), {customerId: resp.data.stripeCustomerId}, null).then(resp => {
			console.log(resp.data)
			/*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
			location.href = resp.data.url;
		  }).catch((err) => {
			console.log(err.response.data.error)
			Swal.fire({
			  icon: 'error',
			  title: 'Oups...',
			  text: err.response.data.error
			})
		  })
	}).catch(err => {
		Swal.fire({
			icon: 'error',
			title: 'Oups...',
			text: err.response.data.error
		})
	});
}

  return (
    <div className={`p-5 shadow border-radius-1`}>
	  {user.subscription ? (
	  <>
      	<h2>My subscription</h2>
		<p className="">You have subscribed to the 🥇 <b className="text-capitalize">{user.subscription}</b> plan.</p>
		<div className={`mt-4`} onClick={(e) => openStripePage(e)}>
			<Button type="button"
			text="Manage my subscription"
			btnStyle="blue"
			/>
		</div>
	  </>
	  ) : (
	  <>
		<h2>My subscription</h2>
		<p>You don't have any subscription... yet.</p>
		<div className={`mt-4`} onClick={() => location.href = "/pricing"}>
			<Button type="button"
			text="Select a plan now"
			btnStyle="blue"
			/>
		</div>
	  </>
	  )}
    </div>
  );
};

SubscriptionContent.propTypes = {
  data: PropTypes.object,
  styles: PropTypes.object
};

export default SubscriptionContent;
