import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";

import PageHeader from "../containers/basic-pages/page-header";
import PricingContent from "../containers/pricing";
import PropTypes from "prop-types";


const Pricing = ({styles}) => {


  return (
    <Fragment>
      <MetaTags>
        <title>Togathr | Pricing</title>
        <meta
          name="description"
          content="About page of React VOIP, Telecom and Cloud Services Template."
        />
		</MetaTags>
    	<LayoutOne> 
			  <PageHeader title="Our plans" subtitle="You want to help us and get the latest features before anyone else ? Get a plan !" /> 
        <PricingContent styles={styles}/>
    	</LayoutOne>
	</Fragment>
  );
};

Pricing.propTypes = {
  data: PropTypes.object,
  styles: PropTypes.object
};

export default Pricing;
