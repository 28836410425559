import React from "react";
import styles from "./Company.module.scss";
import AccountMenu from "../../components/account/accountMenu";
import CompanyContent from "../../components/company/companyContent";

const Company = () => {
  return (
    <div className={`container my-5 ${styles.AccountContainer}`}>
      <div className="row">
          <div className="col-12 col-lg-3 my-lg-5">
            <AccountMenu styles={styles}/>
          </div>
          <div className="col-12 col-lg-9 pl-lg-5 my-5 d-flex flex-column justify-content-center">
            <CompanyContent styles={styles}/>
          </div>
      </div>
    </div>
  );
};

export default Company;
