import PropTypes from "prop-types";
import React from "react";
import Button from "../../UI/button";
import { useState } from 'react';
import axios from "axios";
import Swal from 'sweetalert2'
import ApiUrl from "../../../utils";

const Validation = ({styles}) => {
  const [user, setValidation] = useState({website: "", siret: "", comment: ""});

  const handleChange = e => {
    const { name, value } = e.target;
    setValidation(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const validation = () => {
    const config = {
      headers: { Authorization: 'Bearer ' + localStorage.getItem("token") }
    };
    const body = {
      siret: user.siret,
      comment: user.comment,
      website: user.website
    };
    axios.post(ApiUrl(`validation`), body, config).then(resp => {
      // localStorage.setItem("token", resp.data.token);
      /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
      location.href = "/dashboard";
    }).catch((err) => {
      Swal.fire({
        icon: 'error',
        title: 'Oups...',
        text: err.response.data.error
      })
    });
  }

  return (
      <div className={`${styles.validation.validationBox} row d-flex align-items-center justify-content-center position-relative bg-white shadow p-5 border-radius-2`}>
        <div className="col-12 d-flex align-items-center justify-content-center flex-column">
          <div className="d-flex flex-column align-items-center">
            <div className="form-group">
              <label for="website">Professionnal website</label>
              <input
                  className={`mb-3 form-control`}
                  type="text"
                  id="website"
                  value={user.website}
                  onChange={handleChange}
                  name="website"
                  placeholder="elon@spacex.com"
              />
            </div>
            <div className="form-group">
              <label for="siret">Company Siret</label>
              <input
                className={`mb-3 form-control`}
                type="siret"
                id="siret"
                onChange={handleChange}
                name="siret"
                value={user.siret}
                placeholder="6781 6718 6781 68"
              />
            </div>
            <div className="form-group">
              <label for="comment">Describe your business/project</label>
              <textarea
                className={`mb-3 form-control`}
                type="comment"
                id="comment"
                name="comment"
                value={user.comment}
                onChange={handleChange}
                placeholder="Send rockets and people to Mars."
              ></textarea>
            </div>
            <div onClick={() => validation()}>
              <Button type="button"
                text="Send"
                btnStyle="blue"
                className="w-100"
              />
            </div>
          </div>
        </div>
    </div>
  );
};

Validation.propTypes = {
  data: PropTypes.object,
  styles: PropTypes.object
};

export default Validation;