import PropTypes from "prop-types";
import React from "react";
import Button from "../../UI/button";
import { useState } from 'react';
import axios from "axios";
import Swal from 'sweetalert2'
import GoogleLogin from 'react-google-login';
import ApiUrl from "../../../utils";

const Register = ({styles}) => {
  const [user, setUser] = useState({fullName: "", email: "", password: ""});

  const handleChange = e => {
    const { name, value } = e.target;
    setUser(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const responseGoogle = async (resp) => {
    axios.post(ApiUrl(`google/login`), {token: resp.tokenId}).then(resp => {
      localStorage.setItem("token", resp.data.token);
      /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
      location.href = "/chart";
    }).catch((err) => {
      Swal.fire({
        icon: 'error',
        title: 'Oups...',
        text: err.response.data.error
      })
    })
  };

  const register = () => {
    const config = {
      headers: { Authorization: 'Bearer ' + localStorage.getItem("token") }
    };
    const body = {
      email: user.email,
      password: user.password,
      fullName: user.fullName
    };
    axios.post(ApiUrl(`register`), body, config).then(resp => {
      localStorage.setItem("token", resp.data.token);
      /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
      location.href = "/chart";
    }).catch((err) => {
      Swal.fire({
        icon: 'error',
        title: 'Oups...',
        text: err.response.data.error
      })
    });
  }

  const [mail] = useState(null);
  return (

      <div className={`${styles.register.registerBox} row d-flex align-items-center justify-content-center position-relative bg-white shadow p-5 border-radius-2 mx-4`}>
        <div className="col-12 d-flex align-items-center justify-content-center flex-column">
          <p className="lead text-theme-color text-center my-4">
            Register for free to access the meetings.<br/>
            Already have an account ? <a href="/login" className="text-theme-blue">Login</a>.
          </p>
          <div className="d-flex flex-column align-items-center">
            <input
                className={`my-3`}
                type="text"
                id="full_name"
                value={user.fullName}
                onChange={handleChange}
                name="fullName"
                placeholder="John Doe"
            />
            <input
                className={`my-3`}
                type="email"
                id="email"
                onChange={handleChange}
                name="email"
                value={mail}
                placeholder="myEmail@address.com"
            />
            <input
                className={`my-3`}
                type="password"
                id="password"
                name="password"
                value={user.password}
                onChange={handleChange}
                placeholder="********"
            />
            <div onClick={() => register()}>
              <Button type="button"
                text="Register"
                btnStyle="blue"
                className="w-100"
              />
            </div>
            <p className={`my-3`}>Or</p>
            <GoogleLogin
              clientId="435136721550-9qdh4uie44p9918ekoqpgqui97h8qkha.apps.googleusercontent.com"
              buttonText="Login"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              render={renderProps => (
                <div onClick={renderProps.onClick}>
                    <Button
                      type="button"
                      text="Register with Google"
                      btnStyle="blue"
                      className="w-100"
                    />
                    </div>
              )}
              cookiePolicy={'single_host_origin'}
            />
          </div>
        </div>
    </div>
  );
};

Register.propTypes = {
  data: PropTypes.object,
  styles: PropTypes.object
};

export default Register;
