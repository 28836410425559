import React from "react";
import sliderData from "../../../data/hero-sliders/hero.json";
import Hero from "../../../components/hero-sliders/hero";
import styles from "./Hero.module.scss";

const HeroSlide = () => {

  return (
    <div className={`hero-slider ${styles.heroSlider} ${styles.sliderOne}`}>
      <div className="slideActivation">
          {sliderData &&
            sliderData.map((single, key) => {
              return (
                <Hero
                  data={single}
                  key={key}
                  styles={styles}
                />
              );
            })}
      </div>
    </div>
  );
};

export default HeroSlide;
