import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";

const MobileNavMenu = ({ styles }) => {

  const [isAdmin, setIsAdmin] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  const goToThePortal = (e) => {
    e.preventDefault();
    window.location.href = "https://portal.togathr.io/#/access?token=" + localStorage.getItem("token");
  };

  useEffect(() => {
    const isLogin = localStorage.getItem("token");
    const isAdmin = localStorage.getItem("isAdmin");
    setIsAdmin(isAdmin === "true");
    
    if (isLogin) {
      setIsLogin(true);
    }

  }, []);


  return (
    <nav className={styles.offcanvasNavigation} id="offcanvas-navigation">
      <ul className="mt-4">
        { (!isLogin || window.location.href.includes('logout'))
          &&
          <>
            <li>
              <Link to={process.env.PUBLIC_URL + "/register"}>Register</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/login"}>Login</Link>
            </li>
          </>
        }
        { isLogin && !window.location.href.includes('logout')
          &&
          <li>
            <Link to={process.env.PUBLIC_URL + "/dashboard"}>Dashboard</Link>
          </li>
        }

        <li>
          <Link to={process.env.PUBLIC_URL + "/pricing"}>Pricing</Link>
        </li>

        { isLogin && !window.location.href.includes('logout')
          &&
          <li className={styles.menuItemHasChildren}>
            <Link to={process.env.PUBLIC_URL + "/account"}>Settings</Link>
            <ul className={`${styles.subMenu}`}>
              <li>
                <Link to={process.env.PUBLIC_URL + "/account"}>
                  My account
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/company"}>
                  My company
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/subscription"}>
                  My subscription
                </Link>
              </li>
            </ul>
          </li>
        }
        { isLogin && !window.location.href.includes('logout')
          &&
          <li>
            <Link to={process.env.PUBLIC_URL + "/logout"}>Logout</Link>
          </li>
        }
        { isAdmin && !window.location.href.includes('logout')
          &&
          <li onClick={(e) => goToThePortal(e)}>
            <Link to={process.env.PUBLIC_URL + "/#"} className="text-danger">Admin</Link>
          </li>
        }


        {/* <li>
          <Link to={process.env.PUBLIC_URL + "/about"}>A propos</Link>
        </li> */}
        {/* <li className={styles.menuItemHasChildren}>
          <Link to={process.env.PUBLIC_URL + "/blog"}>Blog</Link> */}
          {/* <ul className={`${styles.subMenu}`}>
            <li>
              <Link to={process.env.PUBLIC_URL + "/blog-left-sidebar"}>
                Blog Left Sidebar
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/blog"}>
                Blog Right Sidebar
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/blog-post-left-sidebar"}>
                Blog Post Left Sidebar
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/blog-post"}>
                Blog Post Right Sidebar
              </Link>
            </li>
          </ul> */}
        {/* </li> */}
        {/* <li>
          <Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link>
        </li> */}
      </ul>
    </nav>
  );
};

export default MobileNavMenu;
