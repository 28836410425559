import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Hero from "../containers/hero-sliders/hero";
import HeroQuickTasks from "../containers/hero-sliders/hero-quick-tasks";
import Reasons from "../containers/reasons";
import { hotjar } from 'react-hotjar';
import axios from "axios";
import ApiUrl from "../utils";
import CookieConsent from "react-cookie-consent";
// import ServiceOne from "../containers/services/service-one";
// import ServiceTwo from "../containers/services/service-two";
// import SoftwareDownloadOne from "../containers/software-downloads/software-download-one";
// import ReactGA from 'react-ga';
// ReactGA.initialize('G-GHNRJB4MJS');
// ReactGA.pageview(window.location.pathname + window.location.search);

const HomeOne = () => {

	useEffect(() => {
		
		hotjar.initialize(2866680, 6);
		hotjar.stateChange('/');
		if (window.location.href.includes("logout"))
			localStorage.removeItem("token");
		if (localStorage.getItem("token")) {
			const config = {
				headers: { Authorization: 'Bearer ' + localStorage.getItem("token") }
			};
			axios.get(ApiUrl(`user/me`), config).then(resp => {
				localStorage.setItem('user', JSON.stringify(resp.data));
			}).catch(err => {
				localStorage.removeItem("token");
				localStorage.removeItem("user");
				window.location.reload(false);
			});
		} else {
			localStorage.removeItem("user");
		}
	});

  return (
    <Fragment>
      <MetaTags>
        <title>Togathr - Meet entrepreneurs online</title>
        <meta
          name="description"
          content="Organize and manage meetings with random entrepreneurs."
        />
        {/* <meta
          property="og:image"
          content="https://www.rent-manager.fr/static/media/s3.2de6bdf5.png"
        /> */}
      </MetaTags>
      <LayoutOne>
        {/* contains parallaxed elements */}
          {/* hero slider */}
          <Hero />
          <HeroQuickTasks />
          <Reasons/>
          {/* service */}
          {/* <ServiceOne /> */}
          {/* feature 3 */}
          {/* <div id="admin"></div> */}
          {/* service with text */}
          {/* <div id= "service">
            <ServiceTwo />
          </div> */}
          {/* software download */}
          {/* <SoftwareDownloadOne /> */}
          {/* blog grid
          <BlogGrid />*/}
      </LayoutOne>
      <CookieConsent
        location="bottom"
        buttonText="I accept"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonClasses={{ background: "#2B373B" }}
        buttonStyle={{ color: "#ffffff", background: "#006BFF", fontSize: "13px" }}
        expires={150}
        enableDeclineButton
        // onDecline={() => {
        //   alert("nay!");
        // }}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
    </Fragment>
  );
};

export default HomeOne;
