import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
// import axios from "axios";
import { HashLink as Link } from 'react-router-hash-link';
// import ApiUrl from "../../../../utils";
import { format } from 'date-fns'
import Button from "../../../UI/button"
import ApiUrl from "../../../../utils";
import axios from "axios";
import Swal from "sweetalert2";
import { atcb_action, atcb_init } from 'add-to-calendar-button';

import canceled from "../../../../assets/images/icons/status/canceled_icon.png";
import success from "../../../../assets/images/icons/status/done_icon.png";
import created from "../../../../assets/images/icons/status/created_icon.png";

const MeetingDetails = ({ styles, status, date, type, id, reRender }) => {

    
    //const [events, setEvents] = useState([]);
    const [buttonTxt, setButtonTxt] = useState("To the meeting");
    const [stat, setStat] = useState(status);
    //  const [loaded, setLoaded] = useState(false);
    let timeleft = 10;
    let check = 0;
    let waitingInterval = 0;
    let checkingInterval = 0;

    const joinMeeting = (roomId) => {
      if (timeleft === 0) {
        window.location.href = `/meet/${roomId}`;
      } else {
        setButtonTxt("Redirection in " + timeleft + " seconds...");
        timeleft -=1;
      }
    }

    const waitingButton = () => {
      if (check === 3)
        check = 0;
      const dots =['.  ', '.. ', '...'];
      setButtonTxt("Waiting other participant " + dots[check]);
      check += 1;
    }

    const cancelMeeting = (id) => {
      const config = {
        headers: { Authorization: 'Bearer ' + localStorage.getItem("token") }
        };
      axios.put(ApiUrl(`event/${id}/cancel`), {}, config)
        .then(resp => {
        reRender();
        setStat("canceled");
        Swal.fire({
          icon: 'success',
          title: 'Appointment canceled'
          })
      });
    }

    const checkIfRoomReady = () => {
      const config = {
        headers: { Authorization: 'Bearer ' + localStorage.getItem("token") }
      };
      axios.post(ApiUrl(`room`), {type: 'one-to-one', filter: []}, config)
      .then(resp => {
        if(resp.data.meetURL && resp.data.status !== "waiting") {
          joinMeeting(resp.data.meetURL);
          setInterval(() => joinMeeting(resp.data.meetURL), 1000);
          clearInterval(waitingInterval);
          clearInterval(checkingInterval);
        }
      });
    }

    const joinRoom = async (e, type) => {
      e.preventDefault();
      const config = {
        headers: { Authorization: 'Bearer ' + localStorage.getItem("token") }
      };
      axios.post(ApiUrl(`room`), {type: type, filter: []}, config)
      .then(resp => {
        if(resp.data.meetURL && resp.data.status !== "waiting") {
          timeleft = 13;
          joinMeeting(resp.data.meetURL);
          setInterval(() => joinMeeting(resp.data.meetURL), 1000);
        } else {
          waitingButton();
          waitingInterval = setInterval(() => waitingButton(), 1000);
          checkingInterval = setInterval(() => checkIfRoomReady(), 5000);
        }
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'Oups...',
          text: err.response.data.message
        })
      });
    }

    useEffect(() => {
      atcb_init();
    }, []);


    return (
      <div>
        <div id={id} className={`${styles.meetingDetails} ${styles.hiddenDetails}`} >
              <div>
                  <p className="text-muted mb-1">
                    {/* <img alt={event.stat} src={event.stat} height="20px" className="mr-2"/> */}
                    {stat === "created" &&
                        <img alt={stat} src={created} height="20px" className="mr-2"/>
                    }
                    {stat === "done" &&
                        <img alt={stat} src={success} height="20px" className="mr-2"/>
                    }
                    {stat === "canceled" &&
                        <img alt={stat} src={canceled} height="20px" className="mr-2"/>
                    }
                    {format(new Date(date), 'dd/MM/yyyy')} - 18h00
                  </p>
                  <h2>{type === 'one-to-one' ? "Random Meet - Duo" : "Random Meet - Multi"}</h2>
                  {stat === "created" &&
                    <>
                      <div className="d-flex flex-row justify-content-between">
                        <div onClick={(e) => joinRoom(e, type)}>
                        <Button
                          type="button"
                          disabled
                          text={buttonTxt}
                          btnStyle="blue"
                        />
                        </div>
                          <div onClick={() => cancelMeeting(id)}>
                          <Button
                            type="button"
                            text="Cancel the meeting"
                            btnStyle="abort"
                            className={`ml-4`}
                          />
                          </div>
                      </div>
                      <p className="mt-4" onClick={e => {
                        atcb_action({
                        name: 'Random Meet - Duo',
                        description: 'Join your meeting through https://www.togathr.io/',
                        startDate: format(new Date(date), 'yyyy-MM-dd'),
                        endDate: format(new Date(date), 'yyyy-MM-dd'),
                        startTime: "18:00",
                          endTime: "19:00",
                        options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'Outlook.com', 'MicrosoftTeams', 'Yahoo'],
                        trigger: "click",
                        timeZone: "Europe/Paris",
                        iCalFileName: "Reminder-Event",
                        })}}>
                        <Link to="#" className="text-muted">Ajouter à mon agenda</Link>
                      </p>
                    </>
                  }
                  {stat === "canceled" &&
                    <>
                      <p>This meet is canceled. If you are now available you can create a new one.</p>
                    </>
                  }
                  {stat === "done" &&
                    <>
                      <p>This meet is done !</p>
                      <p>This place will be use to show some informations about the meet.</p>
                      <p>Any feature idea to put here ? Let us know !</p>
                    </>
                  }


              </div>
            </div>
        </div>
    )
};

MeetingDetails.propTypes = {
  data: PropTypes.object,
  styles: PropTypes.object,
  status: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
};

export default MeetingDetails;