import PropTypes from "prop-types";
import React, { useState, useEffect } from 'react';
// import axios from "axios";
// import ApiUrl from "../../../utils";
// import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import NewMeetModal from "../../modals/NewMeetModal";

const QuickTasks1 = ({ styles, reRender, isFromHome }) => {

  // const [event] = useState({type: "one-to-one", filter: ["marketing", "agency-ceo"]});	
  const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

  // const newEvent = () => {
  //     if (isFromHome === undefined || isFromHome === null) {	
  //         createEvent()
  //     }
  //     else {
  //       /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
  //       location.href = "/dashboard";
  //     }

  // }
  // const createEvent = () => {
  //     const config = {
  //       headers: { Authorization: 'Bearer ' + localStorage.getItem("token") }
  //     };
  //     const body = {
  //       type: event.type,
  //       filter: event.filter
  //     };
  //     axios.post(ApiUrl(`event`), body, config).then(resp => {
	// 	    reRender();
  //     }).catch((err) => {
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Oops...',
  //         text: err.response.data.error
  //       })
  //       reRender();
  //     });
  // }

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);


  return (
    <>
      <div className={`${styles.quickTask} ${styles.quickTask_1} ${scroll ? "" : styles.bounce}`} 
        onClick={handleShow} onTouchTap={handleShow}>
        <span className={`${styles.plus}`}>+</span>
        <h2 className='mb-0'>New Meet</h2>
      </div>

      <Modal show={show} onHide={handleClose} className="">

					<Modal.Body className="p-4">
						<NewMeetModal isFromHome={isFromHome} reRender={reRender}/>
            
					</Modal.Body>
				</Modal>
    </>
  );
};

QuickTasks1.propTypes = {
  data: PropTypes.object,
  styles: PropTypes.object
};

export default QuickTasks1;