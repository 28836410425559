import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const Logo = ({styles}) => {
  return (
    <div className={`${styles.Logo} logo`}>
      <Link to={process.env.PUBLIC_URL + "/"}>
        <h2 className="logo_togathr text-theme-blue my-auto">
          Togathr
          <span className="logo_io text-theme-color">
            .io<sup>alpha</sup>
          </span>
          </h2>
      </Link>
    </div>
  );
};

Logo.propTypes = {
  styles: PropTypes.object
};

export default Logo;
