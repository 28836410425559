import React, { useEffect, useState } from "react";
import axios from "axios";
import ApiUrl from "../../../utils";
import MeetingListItem from "../../../components/dashboard/meetings/MeetingList/MeetingListItem";
import MeetingDetails from "../../../components/dashboard/meetings/MeetingDetails";
import styles from "./DashboardContent.module.scss";

const DashboardContent = ({refresh, reRender}) => {

  const [events, setEvents] = useState([]);

  const getEvents = async () => {
    const config = {
      headers: { Authorization: 'Bearer ' + localStorage.getItem("token") }
    };
    axios.get(ApiUrl(`event?onlyMine=true`), config).then(resp => {
      setEvents(resp.data.data);
    });
  }

  useEffect(() => {
      getEvents();
  }, [refresh]);

  //  onClick={(e) => showEvent(event._id)}

  function showMeetingDetails(id){
    var elements = document.getElementsByClassName(`${styles.activeDetails}`);
    while(elements.length > 0){
        elements[0].classList.remove(`${styles.activeDetails}`)
    }
    document.getElementById(id).classList.add(`${styles.activeDetails}`);
    console.log(id)
  };

  return (
    <div className={`${styles.DashboardContent}`}>
      <div className="container">
        <div className="row">
            <div className='col-12'>
                <h3>My appointments</h3>
            </div>
            <div className="col-md-4">
              <div className={`${styles.meetingList}`}>
                <div className={`${styles.meetingListContainer}`}>

                  { events && 
                    events.sort((a, b) => a[events.date] < b[events.date] ? 1 : -1).map((event)=>(
                      <div onClick={() => showMeetingDetails(event._id)}>
                        <React.Fragment key={"listItem_"+event._id}>
                          <MeetingListItem 
                            id={event._id}
                            status={event.status}
                            date={event.date}
                            type={event.type}
                            styles={styles}/>
                        </React.Fragment>
                      </div>
                    ))
                  }
                  
                </div>
            </div>
            </div>
            <div className={`${styles.MeetingDetailsContainer} col-md-8`}>
              { events && 
                events.map((event)=>(
                    <React.Fragment key={"details_"+event._id}>
                      <MeetingDetails 
                        id={event._id}
                        status={event.status}
                        date={event.date}
                        type={event.type}
                        styles={styles}
                        reRender={reRender}
                      />
                    </React.Fragment>
                  ))
              }
            </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardContent;
