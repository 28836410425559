import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import PageHeader from "../containers/basic-pages/page-header";
import SubscriptionContent from "../containers/subscription";

const Subscription = () => {

  return (
    <Fragment>
      <MetaTags>
        <title>Togathr | Mon tableau de bord</title>
        <meta
          name="description"
          content="About page of React VOIP, Telecom and Cloud Services Template."
        />
		</MetaTags>
    	<LayoutOne>
        <PageHeader title="My subscription" subtitle="Manage your subscription informations." /> 
        <SubscriptionContent/>
    	</LayoutOne>
	</Fragment>
  );
};


export default Subscription;
