import PropTypes from "prop-types";
import React from "react";

import { format } from 'date-fns'

import canceled from "../../../../../assets/images/icons/status/canceled_icon.png";
import success from "../../../../../assets/images/icons/status/done_icon.png";
import created from "../../../../../assets/images/icons/status/created_icon.png";

const MeetingListItem = ({ styles, status, date, type }) => {



  return (
      <div className={`${styles.meetingListItem}`}>
          <p className="text-muted mb-1">
          {/* <img alt={status} src={[{status}]} height="20px" className="mr-2"/> */}
          {status === "created" &&
              <img alt={status} src={created} height="20px" className="mr-2"/>
          }
          {status === "done" &&
              <img alt={status} src={success} height="20px" className="mr-2"/>
          }
          {status === "canceled" &&
              <img alt={status} src={canceled} height="20px" className="mr-2"/>
          }
          {format(new Date(date), 'dd/MM/yyyy')} - 18h00
          </p>
          <h4>{type === 'one-to-one' ? "Random Meet - Duo" : "Random Meet - Multi"}</h4>
      </div> 
  )
};

MeetingListItem.propTypes = {
  data: PropTypes.object,
  styles: PropTypes.object,
  id: PropTypes.string,
  status: PropTypes.string,
  type: PropTypes.string,
};

export default MeetingListItem;