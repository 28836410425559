import PropTypes from "prop-types";
import React, { useState } from "react";
import Button from "../UI/button";

import axios from "axios";
import ApiUrl from "../../utils";
import Swal from "sweetalert2";

const HeaderBtn = ({ styles, isFromHome, reRender }) => {

    const [event] = useState({type: "", filter: []});

	const newMultiEvent = () => {
		  if (isFromHome === undefined || isFromHome === null) {
			  createMultiEvent();
          }
		  else {
			  /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
			  location.href = "/dashboard";
		  }
	}
	const newDuoEvent = () => {
        if (isFromHome === undefined || isFromHome === null)
            createDuoEvent()
        else {
            /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
            location.href = "/dashboard";
        }
    }

    const createDuoEvent = () => {
        const config = {
            headers: { Authorization: 'Bearer ' + localStorage.getItem("token") }
        };
        const body = {
            type: "one-to-one",
            filter: event.filter
        };
        axios.post(ApiUrl(`event`), body, config).then(resp => {
            reRender();		
            Swal.fire({
            icon: 'success',
            title: 'Cool !',
            text: 'New meet created !'
            })
        }).catch((err) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.response.data.error
            })
            reRender();
        });
    }

    const createMultiEvent = () => {
        const config = {
            headers: { Authorization: 'Bearer ' + localStorage.getItem("token") }
        };
        const body = {
            type: "multi",
            filter: event.filter
        };
        axios.post(ApiUrl(`event`), body, config).then(resp => {
            reRender();
            Swal.fire({
                icon: 'success',
                title: 'Cool !',
                text: 'New meet created !'
            })
        }).catch((err) => {
            Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error
        })
        reRender();

        });
    }


  return (
    <>
        <div className="row text-center p-5">
            <div className="col-md-12">
                <h3>🎲 Random Meets</h3>
            </div>
            <div className="col-md-6">
                <div onClick={() => newDuoEvent()} onTouchTap={() => newDuoEvent()}>
                    <Button type="button" btnStyle="blue" text="Duo"/>   
                </div>
            </div>
            <div className="col-md-6">
                <div onClick={() => newMultiEvent()} onTouchTap={() => newMultiEvent()}>
                    <Button type="button" btnStyle="blue" text="Multi"/>
                </div>
            </div>
            <div className="col-md-12">
                <h3 className="mt-5 text-theme-grey-medium">🔧 Custom Meets</h3>
                <p className="lead text-theme-grey-medium">Coming soon...</p>
            </div>
        </div>
    </>
  );
};

HeaderBtn.propTypes = {
  styles: PropTypes.object
};

export default HeaderBtn;
