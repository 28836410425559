import axios from "axios";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import Swal from "sweetalert2";
import Button from "../../../components/UI/button";
import ApiUrl from "../../../utils";

const PricingBox = ({ styles }) => {
	const [isSupporter, setIsSupporter] = useState(false);

	const checkSupporter = () => {
		const isSupporter = JSON.parse(localStorage.getItem('user'))['subscription'];
		if (isSupporter === "supporter") {
			setIsSupporter(true);
		}
	}

	useEffect(() => {
		checkSupporter();
	  }, []);

	const openStripePage = (e) => {
		e.preventDefault();
		const config = {
			headers: { Authorization: 'Bearer ' + localStorage.getItem("token") }
		};
		axios.get(ApiUrl(`user/me`), config).then(resp => {
			axios.post(ApiUrl(`stripe`), {email: resp.data.email}, null).then(resp => {
				console.log(resp.data)
				localStorage.setItem('user', JSON.stringify(resp.data));
				/*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
				location.href = resp.data.url;
			  }).catch((err) => {
				console.log(err.response.data.error)
				Swal.fire({
				  icon: 'error',
				  title: 'Oups...',
				  text: err.response.data.error
				})
			  })
		}).catch(err => {
			Swal.fire({
				icon: 'error',
				title: 'Oups...',
				text: err.response.data.error
			})
		});
	};

	const openStripeManager = (e) => {
		e.preventDefault();
		const config = {
			headers: { Authorization: 'Bearer ' + localStorage.getItem("token") }
		};
		axios.get(ApiUrl(`user/me`), config).then(resp => {
			localStorage.setItem('user', JSON.stringify(resp.data));
			axios.post(ApiUrl(`stripe/portal`), {customerId: resp.data.stripeCustomerId}, null).then(resp => {
				console.log(resp.data)
				/*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
				location.href = resp.data.url;
			  }).catch((err) => {
				console.log(err.response.data.error)
				Swal.fire({
				  icon: 'error',
				  title: 'Oups...',
				  text: err.response.data.error
				})
			  })
		}).catch(err => {
			Swal.fire({
				icon: 'error',
				title: 'Oups...',
				text: err.response.data.error
			})
		});

	}	

  return (

    <div className={`row d-flex align-items-center justify-content-center position-relative`}>
      <div className={`col-12 mx-auto`}>
        <div className={`border-radius-2 shadow px-4 pt-4 pb-5 text-center`}>
			<h2 className={`h1 text-theme-blue`}>
				9€<span className={`h2`}>99</span>
				<span className={`text-theme-color h4 d-block`}>/month</span>
			</h2>
			<ul className={`list-unstyled py-4`}>
				<li className={`mb-4 font-weight-bold lead`}>Lifetime discount on membership</li>
				<li className={`mb-4 lead`}>Exclusive 'Supporter' badge</li>
				<li className={`mb-4 lead`}>Access to staff meetings</li>
				<li className={`mb-4 lead`}>Follow the evolution of the project in videos</li>
				<li className={`lead`}>Your profil visible on the homepage</li>
			</ul>
			{!isSupporter
				&& 
				<div className={``} onClick={(e) => openStripePage(e)}>
					<Button type="button" btnStyle="blue" text="I want to support !" />
				</div>
			}
			{isSupporter
			&& 
			<>
				<p className="font-weight-bold">Thanks for supporting us !</p>
				<div className={`mt-4`} onClick={(e) => openStripeManager(e)}>
					<Button type="button"
					text="Manage my subscription"
					btnStyle="borderBlue"
					/>
				</div>
			</>
			}
        </div>
      </div>
    </div>

  );
};

PricingBox.propTypes = {
  data: PropTypes.object,
  styles: PropTypes.object
};

export default PricingBox;
