import React from "react";
import styles from "./Feedback.module.scss";
import FeedbackForm from "../../components/feedback/feedback-form";

const Feedback = ({roomId}) => {
  return (
    <div className={`${styles.feedbackPageWrapper} d-flex align-items-center`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-9 m-auto text-center">
            <div
              className={`${styles.feedbackContentCentered} d-flex align-items-center justfy-content-center`}
            >
              <FeedbackForm styles={styles} eventId={roomId}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
