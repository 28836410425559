import React from "react";
import styles from "./RegisterForm.module.scss";
import Register from "../../../components/auth/register";

const RegisterForm = () => {
  return (
    <div className={`${styles.registerArea}`}>
        <div className={`${styles.registerContainer}`}>
          <h1 className="text-center h1">Register</h1>
          <Register styles={styles}/>
        </div>
    </div>
  );
};

export default RegisterForm;
