import PropTypes from "prop-types";
import React from "react";
import Button from "../../UI/button";
import { useState } from 'react';
import axios from "axios";
import Swal from "sweetalert2";
import GoogleLogin from 'react-google-login';
import ApiUrl from "../../../utils";

const LoginForm = (styles) => {

  const [user, setUser] = useState({email: "", password: ""});


  const handleChange = e => {
    const { name, value } = e.target;
    setUser(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const responseGoogle = async (resp) => {
    if (resp.tokenId) {
      axios.post(ApiUrl(`google/login`), {token: resp.tokenId}).then(resp => {
        localStorage.setItem("token", resp.data.token);
		const config = { headers: { Authorization: 'Bearer ' + resp.data.token }};
        /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
		axios.get(ApiUrl(`validation`), config).then(resp => {
			if (resp.data.data.length === 0)
				location.href = "/chart";
			else
				location.href = "/dashboard";
		}).catch((err) => {
			location.href = "/dashboard";
		});
      }).catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oups...',
          text: err.response.data.error
        })
      })
    }
  };

  const failureGoogle = async (resp) => {
    console.log(resp);
  };

  const login = () => {
    const body = {
      email: user.email,
      password: user.password
    };
    axios.post(ApiUrl(`login`), body).then(resp => {
      localStorage.setItem("token", resp.data.token);
      const config = { headers: { Authorization: 'Bearer ' + resp.data.token }};
      /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
	  axios.get(ApiUrl(`validation`), config).then(resp => {
	    if (resp.data.data.length === 0)
			location.href = "/chart";
		else
			location.href = "/dashboard";
		}).catch((err) => {
			location.href = "/dashboard";
		});
    }).catch((err) => {
      Swal.fire({
        icon: 'error',
        title: 'Oups...',
        text: err.response.data.error
      })
    });
  }

  return (

      <div className={`${styles.loginBox} row d-flex align-items-center justify-content-center position-relative bg-white shadow p-5 border-radius-2 mx-4`}>
        <div className="col-12 d-flex align-items-center justify-content-center flex-column">
          <p className="lead text-theme-color text-center my-4">
            Login to your account to access the meetings.<br/>
            You don't have an account ? <a href="/register" className="text-theme-blue">Register for free</a>.
          </p>
          <div className="d-flex flex-column align-items-center">
            <input
                className={`my-3`}
                type="mail"
                id="email"
                name="email"
                value={user.email}
                onChange={handleChange}
                placeholder="myEmail@address.com"
            />
            <input
                className={`my-3`}
                type="password"
                id="password"
                name="password"
                value={user.password}
                onChange={handleChange}
                placeholder="********"
            />
            <div onClick={() => login()}>
              <Button
                type="button"
                text="Login"
                btnStyle="blue"
                className="w-100"
              />
            </div>
          </div>
          <p className={`my-3`}>Or</p>
          <GoogleLogin
            clientId="435136721550-9qdh4uie44p9918ekoqpgqui97h8qkha.apps.googleusercontent.com"
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={failureGoogle}
            render={renderProps => (
              <div onClick={renderProps.onClick}>
                  <Button
                    type="button"
                    text="Login with Google"
                    btnStyle="blue"
                  />
              </div>
            )}
            cookiePolicy={'single_host_origin'}
          />
        </div>
    </div>
  );
};

LoginForm.propTypes = {
  data: PropTypes.object,
  styles: PropTypes.object
};

export default LoginForm;
