import PropTypes from "prop-types";
import React from "react";

const AccountMenu = ({ styles }) => {
  return (
    <div className={`py-lg-4 ${styles.AccountMenu}`}>
        <nav>
            <ul className={`pl-0 pl-lg-auto`}>
                <li><a href="/account" className={`${window.location.href.includes("account") ? styles.Current : ""}`}>My account</a></li>
                {/* <li><a href="/profile" className={`${window.location.href.includes("profile") ? styles.Current : ""}`}>My profile</a></li> */}
                <li><a href="/company" className={`${window.location.href.includes("company") ? styles.Current : ""}`}>My company</a></li>
                <li><a href="/subscription" className={`${window.location.href.includes("subscription") ? styles.Current : ""}`}>My subscription</a></li>
            </ul>
        </nav>
    </div>
  );
};

AccountMenu.propTypes = {
  data: PropTypes.object,
  styles: PropTypes.object
};

export default AccountMenu;
