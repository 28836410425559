import PropTypes from "prop-types";
import React, { useState } from 'react';
import axios from "axios";
import ApiUrl from "../../../utils";
import Swal from "sweetalert2";
// import { ReactComponent as IconPayment } from '../../UI/svg-icons/IconPayment.svg';
// import { ReactComponent as IconWebsite } from '../../UI/svg-icons/IconWebsite.svg';
// import { ReactComponent as IconAdmin } from '../../UI/svg-icons/IconAdmin.svg';

const QuickTasks2 = ({ styles, isFromHome, reRender }) => {

	const [event] = useState({type: "one-to-one", filter: ["marketing", "agency-ceo"]});

	const newEvent = () => {
		  if (isFromHome === undefined || isFromHome === null)
			  createEvent()
		  else {
			  /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
			  location.href = "/dashboard";
		  }
  
	}
	const createEvent = () => {
		const config = {
		  headers: { Authorization: 'Bearer ' + localStorage.getItem("token") }
		};
		const body = {
		  type: event.type,
		  filter: event.filter
		};
		axios.post(ApiUrl(`event`), body, config).then(resp => {
		  reRender();		
		  Swal.fire({
            icon: 'success',
            title: 'Cool !',
            text: 'New meet created !'
		  })
		}).catch((err) => {
		Swal.fire({
		  icon: 'error',
		  title: 'Oops...',
		  text: err.response.data.error
		})
		reRender();

	  });
	}

  return (
      <div className={`${styles.quickTask} ${styles.quickTask_2}`}
      onClick={() => newEvent()} onTouchTap={() => newEvent()}>
        {/* {data.id === "1" &&
          <IconPayment height="85px"/>
        }
        {data.id === "2" &&
          <IconWebsite height="85px"/>
        }
        {data.id === "3" &&
          <IconAdmin height="85px"/>
        } */}
        <h2>Random Meet <span>Duo</span></h2>
      </div>
  );
};

QuickTasks2.propTypes = {
  data: PropTypes.object,
  styles: PropTypes.object
};

export default QuickTasks2;