import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import LoginArea from "../containers/auth/login";


const Login = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>Togathr | Login</title>
        <meta
          name="description"
          content="About page of React VOIP, Telecom and Cloud Services Template."
        />
      </MetaTags>
      <LayoutOne>
        <LoginArea/>
      </LayoutOne>
    </Fragment>
  );
}

export default Login;
