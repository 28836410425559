import PropTypes from "prop-types";
import React from "react";

const DashboardHeader = ({ styles, user }) => {
  return (
    <>
      <h1 className={`${styles.title} text-center mt-5 pt-5 w-100 ${user ? "" : "d-none"}`}>{ user ? "Hi " + user.fullName + " !" : ""}</h1>
    </>
  );
};

DashboardHeader.propTypes = {
  data: PropTypes.object,
  styles: PropTypes.object
};

export default DashboardHeader;
