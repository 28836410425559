import React from "react";
import styles from "./ChartItems.module.scss";

const DashboardHeader = ({data}) => {

  return (
    <div className={`${styles.chartItem}`}>
        <p className={`${ data.type === "is" ? "text-success" : "text-danger" } font-weight-bold lead`}>{ data.type === "is" ? "It is" : "It is not" }</p>
        <h3>{data.title}</h3>
        <p>{data.text}</p>
    </div>
  );
};

export default DashboardHeader;
