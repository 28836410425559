import React, {useEffect} from "react";
import axios from "axios";
import Swal from "sweetalert2";
import styles from "./ValidationForm.module.scss";
import Validation from "../../../components/auth/validation";
import ApiUrl from "../../../utils";

const ValidationForm = () => {
  useEffect(() => {
		const config = {
			headers: { Authorization: 'Bearer ' + localStorage.getItem("token") }
		};
		axios.get(ApiUrl(`validation?onlyMine=true`), config).then(resp => {
			console.log(resp.data.data.length);
			if (resp.data.data.length !== 0)
				window.location.href = "/dashboard";
		}).catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Oups...',
        text: err.response.data.error
      })
		});
  }, []);


  return (
    <div className={`${styles.validationArea}`}>
        <div className={`${styles.validationContainer}`}>
          <h1 className="text-center h1">Validate your profile</h1>
          <p className="text-center lead">We ask you this to ensure quality contacts.</p>
          <Validation styles={styles}/>
        </div>
    </div>
  );
};

export default ValidationForm;
