import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import Button from "../../UI/button";
import axios from "axios";
import Swal from 'sweetalert2'
import ApiUrl from "../../../utils";

const CompanyContent = ({ styles }) => {


  const [company, setCompany] = useState('');

  const handleChange = e => {
    const { name, value } = e.target;
    setCompany(prevState => ({
        ...prevState,
        [name]: value
    }));
  };
  
  useEffect(() => {
		const config = {
			headers: { Authorization: 'Bearer ' + localStorage.getItem("token") }
		};
		axios.get(ApiUrl(`validation?onlyMine=true`), config).then(resp => {
			setCompany(resp.data.data[0]);
		}).catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Oups...',
        text: err.response.data.error
      })
		});
  }, []);
  
  const updateCompany = () => {
    const config = {
      headers: { Authorization: 'Bearer ' + localStorage.getItem("token") }
    };
    const body = {
      website: company.website,
      siret: company.siret,
      comment: company.comment,
    };

    axios.put(ApiUrl(`validation`), body, config).then(resp => {
      /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
      Swal.fire({
        icon: 'success',
        title: 'Updated !',
        text: "You'll be notified when your company is validated by our staff.",
      })
    }).catch((err) => {
      Swal.fire({
        icon: 'error',
        title: 'Oups...',
        text: err.response.data.error
      })
    });
  }
  
  return (
    <div className={`p-5 shadow border-radius-1`}>
      <h2>My company</h2>
      <p>By updating those fields, you'll need to wait for our team to validate your profile again.</p>
      <div className="form-group">
          <label htmlFor="website">Professionnal website</label>
          <input
              className={`mb-3 form-control`}
              type="text"
              id="website"
              value={company.website}
              onChange={handleChange}
              name="website"
              placeholder="elon@spacex.com"
          />
      </div>
      <div className="form-group">
          <label htmlFor="siret">Company Siret</label>
          <input
            className={`mb-3 form-control`}
            type="siret"
            id="siret"
            onChange={handleChange}
            name="siret"
            value={company.siret}
            placeholder="6781 6718 6781 68"
          />
      </div>
      <div className="form-group">
          <label htmlFor="comment">Describe your business/project</label>
          <textarea
            className={`mb-3 form-control`}
            type="comment"
            id="comment"
            name="comment"
            value={company.comment}
            onChange={handleChange}
            placeholder="Send rockets and people to Mars."
          ></textarea>
      </div>
      <div onClick={() => updateCompany()}>
          <Button type="button"
            text="Save and send to validation"
            btnStyle="blue"
            className="w-100"
          />
      </div>
    </div>
  );
};

CompanyContent.propTypes = {
  data: PropTypes.object,
  styles: PropTypes.object
};

export default CompanyContent;
