import React from "react";
import Header from "../../../components/dashboard/header";
import QuickTask1 from "../../../components/dashboard/quickTasks/QuickTask1";
import QuickTask2 from "../../../components/dashboard/quickTasks/QuickTask2";
import QuickTask3 from "../../../components/dashboard/quickTasks/QuickTask3";
import styles from "./DashboardHeader.module.scss";

const DashboardHeader = ({user, reRender}) => {

  return (
    <div
      className={`${styles.headerArea}`}
    >
      <div className={`${styles.headerAreaContainer} container`}>
        <div className="row align-items-center">
            <div className="col-12">
                <Header styles={styles} user={user}/>
            </div>
            <div className="col-md-4">
                <QuickTask1 styles={styles} reRender={reRender}/>
            </div>
            <div className="col-md-4">
                <QuickTask2 styles={styles} reRender={reRender}/>
            </div>
            <div className="col-md-4">
                <QuickTask3 styles={styles} reRender={reRender}/>
            </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
