import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Button from "../UI/button";
import { ReactComponent as IconMenu } from '../UI/svg-icons/feather/IconMenu.svg';

import Dropdown from 'react-bootstrap/Dropdown';
import FeatherIcon from 'feather-icons-react';

const HeaderBtn = ({ styles }) => {
  // const [btnTxt, setbtnTxt] = useState('');
  // const [btnStyle, setbtnStyle] = useState('');
  // const [url, setUrl] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [isSupporter, setIsSupporter] = useState(false);

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add(styles.active);
  };

  const goToThePortal = (e) => {
    e.preventDefault();
    window.location.href = "https://portal.togathr.io/#/access?token=" + localStorage.getItem("token");
  };

  const isNotDoingOnboarding = () => {
	  return window.location.href.includes("chart") === false && window.location.href.includes("validation") === false;
  }

  useEffect(() => {
    const isLogin = localStorage.getItem("token");
    const isAdmin = localStorage.getItem("isAdmin");
	const user = JSON.parse(localStorage.getItem("user"));
    let isSupporter = null;
	if (user)
	 isSupporter = user['subscription']

    setIsAdmin(isAdmin === "true");
    if (isLogin) {
      setIsLogin(true);
    }
    if (isSupporter === "supporter") {
      setIsSupporter(true);
    }
  }, []);

  return (
    <div className="header__btn text-right">
      <div className="desktop-button-wrapper d-none d-lg-block">
        <div className="row justify-content-end">
          {isLogin && !window.location.href.includes("pricing") && !window.location.href.includes('logout') && !isSupporter
            &&
            <div className='col-auto pl-3'>
                <Button type="link" url="/pricing" text="Pricing" btnStyle="blue"/>
            </div>
          }
          {isSupporter &&
            <p className="h3 my-auto mx-4" title="You are a premium supporter !">🥇</p>
          }


          { (!isLogin || window.location.href.includes('logout')) && isNotDoingOnboarding()
          &&
            <div className="col-auto">
              <div className="row">
                <div className="pl-3">
                  {!window.location.href.includes("login") &&
                    <Button type="link" url={`/login`} text={`Login`} btnStyle={`borderBlue`}/>
                  }
                </div>
                <div className="pl-3">
                  {!window.location.href.includes("register") &&
                    <Button type="link" url={`/register`} text={`Register`} btnStyle={`blue`}/>
                  }

                </div>
              </div>
            </div>
          }

          {isLogin && isNotDoingOnboarding() && !window.location.href.includes('logout')
          &&
            <div className='col-auto pl-3'>
                {/* <Button type="link" url={url} text={btnTxt} btnStyle={btnStyle}/> */}
                <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic" className={`btn-theme-blue ${styles.DropdownBtn}`}>
                      <FeatherIcon icon="user"/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={`${styles.DropdownList}`}>
                      <Dropdown.Item className={`${window.location.href.includes("dashboard") ? "text-theme-blue" : "text-theme-color"}`} href="/dashboard">
                        <FeatherIcon icon="list" className="mr-2"/> 
                        Dashboard
                      </Dropdown.Item>
                      <Dropdown.Item className={`${window.location.href.includes("account") ? "text-theme-blue" : "text-theme-color"}`} href="/account">
                        <FeatherIcon icon="settings" className="mr-2"/> 
                        Settings
                      </Dropdown.Item>
                      <Dropdown.Item className={`${window.location.href.includes("pricing") ? "text-theme-blue" : "text-theme-color"}`} href="/pricing">
                        { !isSupporter 
                          &&
                          <>
                            <FeatherIcon icon="gift" className="mr-2"/> 
                            Become awesome
                          </>
                        }
                        { isSupporter 
                          &&
                          <>
                            🥇 You are awesome
                          </>
                        }
                      </Dropdown.Item>
                      <Dropdown.Item className="text-theme-color" href="/logout">
                        <FeatherIcon icon="log-out" className="mr-2"/> 
                        Logout
                      </Dropdown.Item>
                      { isAdmin  
                        &&
                        <Dropdown.Item onClick={(e) => goToThePortal(e)} className="text-danger">
                          <FeatherIcon icon="shield" className="mr-2"/>
                          Admin
                        </Dropdown.Item>
                      }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
          }

        </div>
      </div>
      <div className={`${styles.mobileButtonWrapper} d-block d-lg-none`}>
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <IconMenu height="35px"/>
        </button>
      </div>
    </div>
  );
};

HeaderBtn.propTypes = {
  styles: PropTypes.object
};

export default HeaderBtn;
