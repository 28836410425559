import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import axios from "axios";
import ApiUrl from "../utils";
import Swal from "sweetalert2";
import Button from "../components/UI/button";
import PageHeader from "../containers/basic-pages/page-header";
import ChartItems from "../containers/chart/chart-items";
import ChartItemsData from "../data/chart/chart-items";
import PropTypes from "prop-types";



const Chart = ({styles}) => {
    const [user, setUser] = useState(false);
	const [isValidationSent, setIsValidationSent] = useState(false);

    useEffect(() => {
         const config = {
             headers: { Authorization: 'Bearer ' + localStorage.getItem("token") }
         };
         axios.get(ApiUrl(`user/me`), config).then(resp => {
			localStorage.setItem('user', JSON.stringify(resp.data));
             setUser(resp.data);
         }).catch(err => {
             window.location.href = "/login";
         });
		 axios.get(ApiUrl(`validation?onlyMine=true`), config).then(resp => {
			if (resp.data.data.length !== 0)
				setIsValidationSent(true);
		}).catch(err => {
      		Swal.fire({
        		icon: 'error',
        		title: 'Oups...',
        		text: err.response.data.error
      		})
		});
    }, []);

	const setChartSigned = (e) => {
		e.preventDefault();
		const config = {
			headers: { Authorization: 'Bearer ' + localStorage.getItem("token") }
		};
		axios.put(ApiUrl(`user`), {isChartSigned: true}, config).then(resp => {
			/*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
			if (isValidationSent)
				location.href = "/dashboard";
			else
				location.href = "/validation";
		}).catch((err) => {
			Swal.fire({
			  icon: 'error',
			  title: 'Oups...',
			  text: err.response.data.error
			})
		});
	}
  return (
    <Fragment>
      <MetaTags>
        <title>Togathr | Chart</title>
        <meta
          name="description"
          content="About page of React VOIP, Telecom and Cloud Services Template."
        />
		</MetaTags>
    	<LayoutOne> 
			  <PageHeader user={user} title={"Welcome " + user.fullName} subtitle="Please read carefully those few rules before creating your first meeting." /> 
        <div className="container my-5">
          <div className="row">
              <h2 className="col-12 text-center text-theme-color">The <span class="text-theme-blue">Togathr</span> Chart :</h2>
              {ChartItemsData &&
                ChartItemsData.map((single, key) => {
                  return (
                    <div className="col-md-6 mb-5">
                        <ChartItems
                        data={single}
                        key={key}
                        styles={styles}
                        />
                    </div>
                  );
              })}
              <div className="col-12 text-center my-5" onClick={(e) => setChartSigned(e)} >
                <Button type="link" btnStyle="blue" text="I agree" />
              </div>
          </div>
        </div>


    	</LayoutOne>
	</Fragment>
  );
};

ChartItemsData.propTypes = {
  data: PropTypes.object,
  styles: PropTypes.object
};

export default Chart;
