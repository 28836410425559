import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import Button from "../../../components/UI/button";
import axios from "axios";
import Swal from 'sweetalert2'
import ApiUrl from "../../../utils";

const AccountContent = ({ styles }) => {

  const [user, setUser] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;
    setUser(prevState => ({
        ...prevState,
        [name]: value
    }));
  };
  
  useEffect(() => {
		const config = {
			headers: { Authorization: 'Bearer ' + localStorage.getItem("token") }
		};
		axios.get(ApiUrl(`user/me`), config).then(resp => {
			setUser(resp.data);
			localStorage.setItem('user', JSON.stringify(resp.data));
		}).catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Oups...',
        text: err.response.data.error
      })
		});
  }, []);
  
  const updateAccount = () => {
    const config = {
      headers: { Authorization: 'Bearer ' + localStorage.getItem("token") }
    };
    const body = {
      fullName: user.fullName,
      email: user.email,
      // password: user.password,
    };

    axios.put(ApiUrl(`user`), body, config).then(resp => {
      /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
      Swal.fire({
        icon: 'success',
        title: 'Updated !',
        text: "Your account has been updated",
      })
    }).catch((err) => {
      Swal.fire({
        icon: 'error',
        title: 'Oups...',
        text: err.response.data.error
      })
    });
  }

  return (
    <div className={`p-5 shadow border-radius-1`}>
      <h2>My account</h2>
      <div className="form-group">
          <label htmlFor="fullName">Name</label>
          <input
              className={`mb-3 form-control`}
              type="text"
              id="fullName"
              onChange={handleChange}
              name="fullName"
              value={user.fullName}
              placeholder="John Doe"
          />
      </div>
      <div className="form-group">
          <label htmlFor="email">E-mail</label>
          <input
              className={`mb-3 form-control`}
              type="email"
              id="email"
              onChange={handleChange}
              name="email"
              value={user.email}
              placeholder="myEmail@address.com"
              disabled="disabled"
              // disabled={user.authMethod === 'email' ? '' : 'disabled'}

          />
      </div>
      {/* <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
              className={`mb-3 form-control`}
              type="password"
              id="password"
              name="password"
              value={user.password}
              onChange={handleChange}
              placeholder="********"
              // disabled="disabled"
              // disabled={user.authMethod === 'email' ? '' : 'disabled'}
          />
      </div> */}
      <div className={`mt-4`} onClick={() => updateAccount()}>
          <Button type="button"
            text="Save changes"
            btnStyle="blue"
          />
      </div>

    </div>
  );
};

AccountContent.propTypes = {
  data: PropTypes.object,
  styles: PropTypes.object
};

export default AccountContent;
