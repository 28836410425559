import React from "react";
import { BrowserRouter as Switch, BrowserRouter, Route } from "react-router-dom";
import HomeOne from "./pages/HomeOne";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Validation from "./pages/Validation";
import FreeTrial from "./pages/FreeTrial";
import Dashboard from "./pages/Dashboard";
import Account from "./pages/Account";
// import Profile from "./pages/Profile";
import Company from "./pages/Company";
import Chart from "./pages/Chart";
import Pricing from "./pages/Pricing";
import NotFound from "./pages/NotFound";
import Meeting from "./pages/Meeting";
import Feedback from "./pages/Feedback";
import Subscription from "./pages/Subscription";

function App() {
  return (
    <BrowserRouter>
      <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            component={HomeOne}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            component={Contact}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/login"}`}
            component={Login}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/register"}`}
            component={Register}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/validation"}`}
            component={Validation}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/logout"}`}
            component={HomeOne}
          />
          <Route
            path={process.env.PUBLIC_URL + "/dashboard"}
            component={Dashboard}
          />
          <Route
            path={process.env.PUBLIC_URL + "/account"}
            component={Account}
          />
          {/* <Route
            path={process.env.PUBLIC_URL + "/profile"}
            component={Profile}
          /> */}
          <Route
            path={process.env.PUBLIC_URL + "/company"}
            component={Company}
          />
		      <Route
            path={process.env.PUBLIC_URL + "/subscription"}
            component={Subscription}
          />
          <Route
            path={process.env.PUBLIC_URL + "/chart"}
            component={Chart}
          />
          <Route
            path={process.env.PUBLIC_URL + "/pricing"}
            component={Pricing}
          />
          <Route
            path={process.env.PUBLIC_URL + "/meet/:id"}
            component={Meeting}
          />
          <Route
            path={process.env.PUBLIC_URL + "/feedback/:id"}
            component={Feedback}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/free-trial"}`}
            component={FreeTrial}
          />
          <Route
            path={process.env.PUBLIC_URL + "/not-found"}
            component={NotFound}
          />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
