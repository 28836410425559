import PropTypes from "prop-types";
import { HashLink as Link } from 'react-router-hash-link';
import styles from "./Footer.module.scss";
import React from "react";


const Footer = ({ footerBg }) => {



  return (
    <footer
      className="footer-area">
      <div
        className={`${styles.footerTop}`}>
        <div className="container">
          <div className="row">
            {/* Start Single Wedget */}
            <div className="col-12 col-sm-6 col-lg-3">
              <div className={styles.singleWidget}>
                <div className={styles.logo}>
                  <Link to={process.env.PUBLIC_URL + "/"}>
                    <h2 className="text-white my-auto">Togathr<span className="logo_18 text-theme-color">.io<sup>alpha</sup></span></h2>
                  </Link>
                </div>
                <div className={styles.content}>
                  <p>
                    Meet a random entrepreneur every Wednesday at 18'.
                  </p>
                </div>
              </div>
            </div>
            {/* End Single Wedget */}
            <div className="col-12 col-sm-6 col-lg-2 offset-lg-1 xs__mt--40"></div>
            {/* Start Single Wedget */}
            <div className="col-12 col-sm-6 col-lg-2 offset-lg-1 xs__mt--40">
              <div className={styles.singleWidget}>
                <h2 className={styles.ftTitle}>Site</h2>
                <ul className={styles.ftContactLink}>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/login"}>
                      Login
                    </Link>
                  </li>

                </ul>
              </div>
            </div>
            {/* End Single Wedget */}
            {/* Start Single Wedget */}
            <div className="col-lg-2 offset-lg-1 col-md-6 col-sm-6 col-12 sm__mt--40 md__mt--40">
              <div className={styles.singleWidget}>
                <h2 className={styles.ftTitle}>Infos</h2>
                <ul className={styles.ftContactLink}>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "#/"}>Legal Notice</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "#/"}>
                      CGU 
                    </Link>
                  </li>
				  <li>
                    <Link to={{  pathname: "https://bit.ly/3OBKfwC" }} target="_blank">
                      Roadmap
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* End Single Wedget */}
            {/* Start Single Wedget */}
            {/* <div className="col-lg-2 offset-lg-1 col-md-6 col-sm-6 col-12 sm__mt--40 md__mt--40">
              <div className={styles.singleWidget}>
                <h2 className={styles.ftTitle}>Blog</h2>
                <ul className={styles.ftContactLink}>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "#/"}>
                      Tout les articles
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "#/"}>
                      Lancer son site
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "#/"}>Découvrir le panel</Link>
                  </li>
                </ul>
              </div>
            </div> */}
            {/* End Single Wedget */}
          </div>
        </div>
        <div
        className={`${styles.copyright}`}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className={styles.copyrightInner}>
                <p className="copyright-text">
                  &copy; Togathr - {new Date().getFullYear()} - Made with Love and Coffee{" "}
                  in France
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      

    </footer>
  );
};

Footer.propTypes = {
  footerBg: PropTypes.string
};

export default Footer;
