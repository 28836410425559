import React from "react";
import styles from "./LoginForm.module.scss";
import LoginForm from "../../../components/auth/login";

const loginArea = () => {
  return (
    <div className={`${styles.loginArea}`}>
        <div className={`${styles.loginContainer}`}>
          <h1 className="text-center h1">Login</h1>
          <LoginForm styles={styles}/>
        </div>
    </div>
  );
};

export default loginArea;
