import React from 'react';
import styles from "./Reasons.module.scss";
import Button from "../../components/UI/button";
import { HashLink as Link } from 'react-router-hash-link';
import { ReactComponent as HomeIllus} from '../../components/UI/svg-illus/HomeIllus.svg'

const Reasons = () => {

  return (
    <>
      <div className={`${styles.reason} ${styles.reasonsContainer} container`}>
        <div className={`${styles.reason} row align-items-center`}>
          <div className="col-12">
            <h2 className="text-center">Why use Togathr ?</h2>
          </div>
            <div className="col-md-4">
              <div className={`${styles.card}`}>
                <h3>Expand your network</h3>
                <p>Connect with entrepreneurs from around the world and find solutions to your challenges together.</p>
                <Link to="#" className="font-weight-bolder text-theme-blue">Tell me more</Link>
              </div>
            </div>
            <div className="col-md-4">
              <div className={`${styles.card}`}>
                <h3>Meet new people</h3>
                <p>Inspire and get inspired by business owners by talking directly to them.</p>
                <Link to="#" className="font-weight-bolder text-theme-blue">Tell me more</Link>
              </div>
            </div>
            <div className="col-md-4">
              <div className={`${styles.card}`}>
                <h3>Create opportunities</h3>
                <p>Collaborate to solve mutual questionnings, grow as fast as your business.</p>
                <Link to="#" className="font-weight-bolder text-theme-blue">Tell me more</Link>
              </div>
            </div>
        </div>
      </div>
      <div className={`${styles.reason} ${styles.reasonsContainer} container`}>
        <div className={`${styles.reason} row align-items-center`}>
          <div className="col-md-6">
            <h2>The power of connection</h2>
            <p>Connect with entrepreneurs from around the world and find solutions to your challenges together.</p>
            <p className="mb-4">Connect with entrepreneurs from around the world and find solutions to your challenges together.</p>
            <Button type="link" to="#" btnStyle="blue" text="Tell me more"/>
          </div>
          <div className="col-md-6">
            <HomeIllus/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reasons;
