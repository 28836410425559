import React, { Fragment } from "react";
import { useParams } from 'react-router-dom';
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import FeedbackForm from "../containers/feedback";


const Feedback = () => {

  const { id } = useParams()

  return (
    <Fragment>
      <MetaTags>
        <title>Togathr | Feedback</title>
        <meta
          name="description"
          content="About page of React VOIP, Telecom and Cloud Services Template."
        />
      </MetaTags>
      <LayoutOne>
        <FeedbackForm roomId={id}/>
      </LayoutOne>
    </Fragment>
  );
};

export default Feedback;
