import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import axios from "axios";
import ApiUrl from "../utils";
// import Breadcrumb from "../components/UI/breadcrumb";
import DashboardHeader from "../containers/dashboard/dashboardHeader";
import DashboardContent from "../containers/dashboard/dashboardContent";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";



const Dashboard = () => {
	const [user, setUser] = useState(false);
	const [refresh, setRefresh] = useState(0);
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const reRender = () => {
		setRefresh(refresh + 1);
		console.log("Refresh:", refresh);
	};

	useEffect(() => {
		const config = {
			headers: { Authorization: 'Bearer ' + localStorage.getItem("token") }
		};
		axios.get(ApiUrl(`user/me`), config).then(resp => {
			setUser(resp.data);
			localStorage.setItem('user', JSON.stringify(resp.data));
			localStorage.setItem("isAdmin", resp.data.role === "admin");
			if (resp.data.isChartSigned === false)
				window.location.href = "/chart";
		}).catch(err => {
			window.location.href = "/login";
		});

		//show confirmation modal
		if (window.location.href.includes('session_id')) {
			handleShow()
		}
	}, []);

  return (
    <Fragment>
      <MetaTags>
        <title>Togathr | Mon tableau de bord</title>
        <meta
          name="description"
          content="About page of React VOIP, Telecom and Cloud Services Template."
        />
		</MetaTags>
		{user ? (
			<LayoutOne>
				{/* breadcrumb */}
				{/* <Breadcrumb title="Mon tableau de bord" /> */}
				<DashboardHeader user={user} reRender={reRender}/> 
				<DashboardContent refresh={refresh} reRender={reRender}/>
				<Modal show={show} onHide={handleClose}>
					<Modal.Header className="p-4">
						<Modal.Title className="mb-0">Thank You ! 💙</Modal.Title>
					</Modal.Header>
					<Modal.Body className="p-4">
						<strong>Wow !</strong><br/>
						Thanks for your support, you just made two developers very happy.<br/> 
						<strong>You can now enjoy our Premium features !</strong>
					</Modal.Body>
					<Modal.Footer className="p-4">
						<Button className="btn-theme-blue btn-md" onClick={handleClose} text="Got it !">
							Got it !
						</Button>
					</Modal.Footer>
				</Modal>
			</LayoutOne>
		) : null}
	</Fragment>
  );
};

export default Dashboard;
