import React from "react";
import PricingBox from "../../components/pricing/pricing-box/PricingBox";

const Pricing = ({styles}) => {

  return (
    <div className="container my-5">
      <div className="row">
          <div className="col-12 col-lg-5 my-5">
            <PricingBox styles={styles}/>
          </div>
          <div className="col-12 col-lg-7 pl-lg-5 my-5 d-flex flex-column justify-content-center">
              <h2>Supporter Membership</h2>
              <p>
                L’abonnement Supporter est accessible provisoirement et permet de financer le lancement du projet. Il ne sera plus accessible lorsque l’abonnement premium sera disponible.
              </p>
              <ul className="">
                <li className={`mb-3`}>🥇 Obtenez un badge ‘Supporter’ exclusif.</li>
                <li className={`mb-3`}>📸 Avec votre accord, nous pouvons faire apparaitre votre profil sur la page d'accueil pour montrer aux nouveaux utilisateurs les personnes qu'ils pourront rencontrer sur notre plate-forme.</li>
                <li className={`mb-3`}>👷‍♂️ Recevez chaque semaine des news exclusives (mails, vidéos) à propos l’évolution des nouvelles fonctionnalité pour suivre l’évolution du projet en live.</li>
                <li className={`mb-3`}>🗨️ Accès aux réunions du staff, une fois par mois, un super moyen pour en savoir plus sur les coulisses de Togathr et échanger directement avec nous.</li>
                <li>💳 Votre abonnement basculera automatiquement sur la formule PREMIUM quand celle-ci sera disponible. Vous bénéficierez d’un prix réduit à vie.</li>
              </ul>
          </div>
      </div>
    </div>
  );
};

export default Pricing;
