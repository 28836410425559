import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import Button from "../../UI/button";

const Hero = ({ styles }) => {
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    const isLogin = localStorage.getItem("token");

    if (isLogin)
    setIsLogin(true);
    
  }, []);

  return (

    <div className={ `${styles.slide} ${styles.sliderFixedHeight} ${styles.bgGrey} 
      row d-flex align-items-center justify-content-center position-relative`}>
        <div className={`${styles.wave}`}></div>
        <div className={`${styles.wave}`}></div>
        <div className={`${styles.wave}`}></div>
        <div className={`${styles.wave}`}></div>
        <div className="col-12 d-flex align-items-center justify-content-center flex-column text-center">
          <h1 className="text-center">This Wednesday,<br/> Meet an entrepreneur</h1>
          <p className="lead text-theme-blue my-4">Meet a random business creator online every Wednesday at 18'.</p>
          <div className={`${isLogin ? 'd-none' : 'd-flex'} align-items-center mt-4 `}>
            <Button type="link" url={process.env.PUBLIC_URL + "/register"} text={'Register'} btnStyle="blue"/> 
          </div>
        </div>
    </div>

  );
};

Hero.propTypes = {
  data: PropTypes.object,
  styles: PropTypes.object
};

export default Hero;
