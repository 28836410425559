import React, { Fragment, useState, useEffect } from "react";
import Jitsi from 'react-jitsi';
import { useParams } from 'react-router-dom';
import MetaTags from "react-meta-tags";
import axios from 'axios';
import LayoutOne from "../layouts";
import ApiUrl from "../utils";


const Meeting = () => {
  const { id } = useParams()
  const [user, setUser] = useState(false);

  useEffect(() => {
    const config = {
      headers: { Authorization: 'Bearer ' + localStorage.getItem("token") }
    };
    axios.get(ApiUrl(`user/me`), config).then(resp => {
	  localStorage.setItem('user', JSON.stringify(resp.data));
      setUser(resp.data);
    }).catch(err => {
      window.location.href = "/login";
    });
  }, []);

  const handleAPI = (JitsiMeetAPI) => {
    JitsiMeetAPI.addListener('videoConferenceLeft', () => {
      window.location.href = "/feedback/" + id;
    });
  }

  return (
    <Fragment>
      <MetaTags>
        <title>Togathr | Meeting</title>
        <meta
          name="description"
          content="Meet business creators on a weekly basis."
        />
      </MetaTags>
      <LayoutOne>
		{user.fullName ?
			<Jitsi
			roomName={id}
			displayName={user.fullName}
			onAPILoad={handleAPI}
			containerStyle={{ width: '90%', height: 'calc( 100vh - 70px )', margin: '70px auto' }}
			interfaceConfig={{
				SHOW_CHROME_EXTENSION_BANNER: false
			}}
			config={{
				prejoinPageEnabled:false,
				disableDeepLinking: true,
				startWithAudioMuted: false,
				startWithVideoMuted: true,
				enableInsecureRoomNameWarning: false,
				toolbarButtons: ['microphone', 'camera', 'chat', 'desktop', 'fullscreen', 'hangup'],
				showChromeExtensionBanner: false,
			}}
			/> : <p>Loading...</p>
		  }
      </LayoutOne>
    </Fragment>
  );
};

export default Meeting;
