import PropTypes from "prop-types";
import React from "react";
import Button from "../../UI/button";
import { useState, useEffect } from 'react';
import axios from "axios";
import Swal from 'sweetalert2'
import ApiUrl from "../../../utils";

const FeedbackForm = ({styles}) => {
  const [eventId, setEvent] = useState([]);
  const [hover, setHover] = useState("3");
  const [rating, setRating] = useState("3");
  const [user, setFeedback] = useState({note: rating, feedback: ""});
  
  const handleChange = e => {
    const { name, value } = e.target;
    setFeedback(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  
  
  const getEvent = async () => {

    const config = {
      headers: { Authorization: 'Bearer ' + localStorage.getItem("token") }
    };
    axios.get(ApiUrl(`event/unreview`), config).then(resp => {
      setEvent(resp.data[0]._id);
      const eventId = resp.data[0]._id;
      console.log(eventId)
    });
  }
    
  useEffect(() => {
    getEvent();
  }, []);

  const feedback = () => {

    const config = {
      headers: { Authorization: 'Bearer ' + localStorage.getItem("token") }
    };
    
    const body = {
      note: rating,
      feedback: user.feedback,
    };

    axios.put(ApiUrl(`event/`+ eventId), body, config).then(resp => {
      /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
      Swal.fire({
        icon: 'success',
        title: 'Thank you !',
        text: 'Thanks for helping us, you are great.'
      });
      setTimeout(() => {location.href = "/dashboard";}, 4000);
      
    }).catch((err) => {
      Swal.fire({
        icon: 'error',
        title: 'Oups...',
        text: err.response.data.error
      })
    });
  }

  return (

      <div className={`${styles.feedbackBox} row d-flex align-items-center justify-content-center position-relative bg-white shadow p-5 border-radius-2`}>
        <div className="col-12 d-flex align-items-center justify-content-center flex-column">
          <h1 className="h2 mb-0">Wait a minute !</h1>
          <p className="lead text-theme-color text-center mt-3 mb-4">
            What did you think about this meeting ? 😀<br/>
          </p>

          <div className="d-flex flex-column align-items-center">
            <div id="rating-stars" className="form-group my-4">
                <label htmlFor="note">Rating</label>
                <br/>
                {[...Array(5)].map((star, index) => {
                    index += 1;
                    return (
                      <button
                        type="button"
                        style={{backgroundColor:"transparent", border:"none", outline:"none", cursor:"pointer"}}
                        key={index}
                        className={`${index <= (hover || rating) ? "text-theme-blue star" : "text-theme-grey-light"} `}
                        onClick={
                          () => {setRating(index);setFeedback[0](index)}
                        }
                        onChange={handleChange}
                        onMouseEnter={() => setHover(index)}
                        onMouseLeave={() => setHover(rating)}
                        value={index}
                      >
                        <span className="h2">&#9733;</span>
                      </button>
                    );
                })}

                {/* <input
                    className={`mb-3 form-control px-0`}
                    type="range"
                    id="note"
                    min="1"
                    max="5"
                    value={user.note ? user.note : 3}
                    onChange={handleChange}
                    name="note"
                /> */}
            </div>

            <div className="form-group my-4">
                <label htmlFor="feedback">Your comment</label>
                <textarea
                    className={`mb-3 form-control px-3`}
                    type="feedback"
                    id="feedback"
                    name="feedback"
                    rows="4"
                    cols="40"
                    value={user.feedback}
                    onChange={handleChange}
                    placeholder="Any comment ?"
                ></textarea>
            </div>
            <div onClick={() => feedback()}>
              <Button type="button"
                text="Send feedback"
                btnStyle="blue"
                className="w-100"
              />
            </div>
          </div>
        </div>
    </div>
  );
};

FeedbackForm.propTypes = {
  data: PropTypes.object,
  styles: PropTypes.object
};

export default FeedbackForm;
