import React from "react";
import styles from "./PageHeader.module.scss";

const DashboardHeader = ({user, title, subtitle}) => {

  return (
    <div
      className={`${styles.headerArea}`}
    >
      <div className={`${styles.headerAreaContainer} container `}>
        <div className="row align-items-center">
            <div className="col-12 text-md-center">
                <h1>{title}</h1>
                <p>{subtitle}</p>
            </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
